import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Page } from 'cccisd-laravel-report';
import style from './style.css';
import { initialValues } from '../Builder';
import devTagsQuery from './devTagsQuery.graphql';
import { client } from 'cccisd-apollo';
import _get from 'lodash/get';

// assets
import logo from './assets/Logos/logo.png';
import footerImage from './assets/Logos/footer_image.png';
import actionTipIcon from './assets/Icons/bell.png';

const Fortress = window.cccisd.fortress;

const ExecutiveFunctionReport = props => {
    const { settings } = props;

    const [devTagsData, setDevTagsData] = useState({});

    // inserting initialValues for the case that they don't yet exist through settings (Report GUI builder in app settings)
    for (const key of Object.keys(initialValues)) {
        if (!(key in settings)) {
            settings[key] = initialValues[key];
        } else {
            for (const subkey of Object.keys(initialValues[key])) {
                if (!(subkey in settings[key])) {
                    settings[key][subkey] = initialValues[key][subkey];
                }
            }
        }
    }

    useEffect(() => {
        const pawnId = Fortress.user.acting.id;
        const pawnHash = Fortress.user.acting.random_hash;

        // get user survey answers
        const getMetricsData = async () => {
            const result = await client.query({
                query: devTagsQuery,
                fetchPolicy: 'network-only',
                variables: {
                    pawnId,
                    pawnHash,
                },
            });

            let queryData = {};
            queryData = result.data.roles.anyRoleList[0].assignmentProgressList;
            if (queryData.length > 0) {
                queryData = queryData[0].devTags;
            }

            setDevTagsData(queryData);
        };

        if (!props.isPreview) {
            getMetricsData();
        }
    }, []);

    return (
        <>
            {settings.page1.show && <Page1 {...settings.page1} devTagsData={devTagsData} settings={settings} />}
            {settings.page2.show && <Page2 {...settings.page2} devTagsData={devTagsData} settings={settings} />}
            {settings.page3.show && <Page3 {...settings.page3} devTagsData={devTagsData} settings={settings} />}
        </>
    );
};

const getScoreTitle = score => {
    score = parseInt(score, 10);

    if (score === 5 || score === 4) {
        return 'High';
    }
    if (score === 3) {
        return 'Moderate';
    }
    if (score === 1 || score === 2) {
        return 'Low';
    }
    return '';
};

const getDevTag = (devTagsData, tag) => {
    return _get(devTagsData, tag, '');
};

const getStrengths = devTagsData => {
    const strengthDevTags = {
        LearnsBestVisually: 'Learns best visually',
        Creative: 'Creative',
        LearnsSmallChunks: 'Learns best in small chunks at a time',
        ConstructsBuildings: 'Constructs buildings and interesting projects',
        Humor: 'Has a sense of humor',
        Artistic: 'Artistic',
        RemembersDetails: 'Remembers details',
        RemembersFacts: 'Remembers lots of facts, especially about favorite topics',
        Engaging: 'Engaging',
        Caring: 'Caring',
        Loving: 'Loving',
        Generous: 'Generous',
        Kind: 'Kind',
        Thoughtful: 'Thoughtful',
        GreatWithTechnology: 'Great with technology',
        Persistent: 'Persistent',
    };

    let res = [];

    Object.keys(strengthDevTags).forEach(tag => {
        let value = getDevTag(devTagsData, tag);
        if (value === '1') {
            res.push(strengthDevTags[tag]);
        }
    });

    return res;
};

const Header = props => {
    const { showGreenBox, page } = props;

    return (
        <>
            <div className={style.header_container}>
                <img src={logo} alt="" className={style.header_logo} />
            </div>
            <div className={style.header_line} />
            <HeaderTitle />
            {page === 1 && (
                <div>
                    {`This profile uses your answers about your child to rate their executive function skills. The
                    Frequency of Difficulty column indicates their level of struggle with the executive function. Though
                    your child might be struggling with several executive function skills, we recommend that you focus
                    on one at a time to give you and your child an opportunity to make progress. There's an action tip
                    for each executive function to support your problem solving.`}
                </div>
            )}
            {showGreenBox && (
                <div className={style.header_green_box}>
                    <div style={{ width: '30%' }} className={style.green_box_descriptions}>
                        Executive Function
                    </div>
                    <div style={{ width: '30%' }} className={style.green_box_descriptions}>
                        Description
                    </div>
                    <div
                        style={{
                            width: ' 100px',
                            textAlign: 'left',
                            position: 'relative',
                            left: '200px',
                            top: '-8px',
                        }}
                        className={style.green_box_descriptions}
                    >
                        Frequency of Difficulty
                    </div>
                </div>
            )}
        </>
    );
};

const Footer = props => {
    const { footerText } = props;
    let value = _get(footerText, 'text', initialValues.footerText.text);
    if (value === '') {
        value = initialValues.footerText.text;
    }

    return (
        <div style={{ position: 'absolute', bottom: '0', width: '100%' }}>
            <span className={style.footer_text}>{value}</span>
            <img
                src={footerImage}
                alt=""
                style={{
                    height: '20px',
                    right: '0px',
                    position: 'absolute',
                }}
            />
        </div>
    );
};

const HeaderTitle = () => {
    return (
        <div className={style.title}>
            <span className={style.color_green}>EF Overview </span>Executive Function Profile
        </div>
    );
};

const CategorySection = props => {
    const { title, description, score, actionTip } = props;

    return (
        <>
            <div className={style.category_section}>
                <div className={style.category_section_row_1}>
                    <div className={style.category_section_title}>{title}</div>
                    <div className={style.category_section_description}>{description}</div>
                    <div className={style.category_section_score}>{score}</div>
                </div>
                <div className={style.category_section_row_2}>
                    <img src={actionTipIcon} alt="" className={style.action_tip_icon} />
                    <span className={style.action_tip_text}> Action Tip:</span>
                    <div className={style.action_tip_content}>{actionTip}</div>
                </div>
            </div>
        </>
    );
};

const getSortedCategories = devTagsData => {
    const categories = {
        Flexibility: 'Flexibility',
        Organization: 'Organization',
        Planning: 'Planning',
        Initiation: 'Initiation',
        WorkingMemory: 'Working Memory',
        Inhibition: 'Inhibition',
        SelfMonitoring: 'Self-Monitoring',
        EmotionalControl: 'Emotional Control',
    };

    let scores = [];

    Object.keys(categories).forEach((categoryTag, i) => {
        let value = getDevTag(devTagsData, categoryTag);

        scores.push({ category_title: categories[categoryTag], value: parseInt(value, 10) });
    });

    scores.sort((a, b) => {
        if (a.value > b.value) {
            return -1;
        }
        if (a.value < b.value) {
            return 1;
        }
        return 0;
    });

    return scores;
};

const categoryDescriptions = {
    Flexibility: {
        description:
            'The ability to shift from one thing to the next without getting stuck, adapt to new situations, and adjust to unexpected changes in routine.',
        actionTip:
            "Start talking to your child today about “Plan B.” Anytime something doesn't go as expected, say, “We need a Plan B.” Create a Plan B anytime you expect Plan A might not work out. Say, “Let's come up with a Plan B before we leave the house.”",
    },
    Organization: {
        description: 'The ability to keep ideas and things organized, process the big picture, and set priorities.',
        actionTip:
            'Label shelves in the bedroom, hooks in the closet, and pockets in a notebook with specific items that go there. Take a picture of what the organized space looks like. This can become the goal each time your child is organizing a space.',
    },
    Planning: {
        description: 'The ability to come up with the steps necessary to reach a goal and to prioritize the steps.',
        actionTip:
            'Break big, overwhelming tasks, like cleaning the bedroom, doing homework, or getting ready for school into little steps. Praise your child when they finish each step.',
    },
    Initiation: {
        description: 'The ability to start a task.',
        actionTip: `When your child can't get started, help them identify what the goal is, why the goal is important, and the first step. Then set a timer and see if your child can “beat the clock” to complete the first step.`,
    },
    'Working Memory': {
        description: 'The ability to hold information in your head while you do something.',
        actionTip:
            "Put a small whiteboard in the kitchen, the bathroom, and your child's bedroom. Write down any requests or directions you give, and then let your child check off each step as they complete it.",
    },
    Inhibition: {
        description: 'The ability to think before acting and stay in control.',
        actionTip:
            "Praise your child, or give a thumbs up sign every time you see them wait their turn, refrain from interrupting you, or control an impulse. Connect that behavior to something that is important to your child. Offer specific praise like, “Thank you for waiting with a calm body and a quiet voice. That helps me remember everything I need to order for dinner, including your French fries. I wouldn't want to forget those!”",
    },
    'Self-Monitoring': {
        description:
            'The ability to track your own performance, check work before handing it in, and slow down to do a job right.',
        actionTip:
            "Talk at dinner about a time when you didn't feel like checking your work, but you did it anyway and caught a mistake. Or a time when your self-monitoring positively affected your child's life. For example, “At the store today, I thought I was finished and then I thought, I better double check my list, and I almost forgot our ice cream for tonight! I'm so glad I double checked my list.”",
    },
    'Emotional Control': {
        description:
            'The ability to identify your different emotions and to cope with disappointing and upsetting events by using strategies to bring emotions in check.',
        actionTip:
            'Talk with your child about what overwhelms them—Noise? Hunger? The unexpected? Can you reduce that? For example, with ear plugs, food, or predicting a change before it happens?',
    },
};

const Page1 = props => {
    const devTagsData = props.devTagsData;
    const categories = getSortedCategories(devTagsData).filter((val, i) => i < 4);

    return (
        <Page>
            <div className={style.page}>
                <Header showGreenBox page={1} />

                <div className={style.categories_container}>
                    {categories.map((categoryData, i) => {
                        return (
                            <CategorySection
                                title={categoryData.category_title}
                                description={categoryDescriptions[categoryData.category_title].description}
                                score={getScoreTitle(categoryData.value)}
                                actionTip={categoryDescriptions[categoryData.category_title].actionTip}
                                key={i}
                            />
                        );
                    })}
                </div>
                <Footer {...props.settings} />
            </div>
        </Page>
    );
};

const Page2 = props => {
    const devTagsData = props.devTagsData;
    const categories = getSortedCategories(devTagsData).filter((val, i) => i >= 4);

    return (
        <Page>
            <div className={style.page}>
                <Header showGreenBox />
                <div className={style.categories_container}>
                    {categories.map((categoryData, i) => {
                        return (
                            <CategorySection
                                title={categoryData.category_title}
                                description={categoryDescriptions[categoryData.category_title].description}
                                score={getScoreTitle(categoryData.value)}
                                actionTip={categoryDescriptions[categoryData.category_title].actionTip}
                                key={i}
                            />
                        );
                    })}
                </div>
                <Footer {...props.settings} />
            </div>
        </Page>
    );
};

const Page3 = props => {
    const devTagsData = props.devTagsData;
    const LeastPowered = getDevTag(devTagsData, 'LeastPowered');
    const MostPowered = getDevTag(devTagsData, 'MostPowered');
    const strengths = getStrengths(devTagsData);

    if (LeastPowered || MostPowered || strengths.length > 0) {
        return (
            <Page>
                <div className={style.page}>
                    <Header showGreenBox={false} />
                    {(LeastPowered || MostPowered) && (
                        <div className={style.green_box_section_container}>
                            <div className={style.green_box_section}>Your {`Child's`} Executive Function Power</div>
                            {MostPowered && (
                                <div style={{ paddingTop: '25px' }}>
                                    <div style={{ marginBottom: '12px' }}>
                                        My {`child's`} executive functions skills are <b>most</b> powered when:
                                    </div>
                                    <div style={{ marginLeft: '15px' }}>{LeastPowered}</div>
                                </div>
                            )}
                            {LeastPowered && (
                                <div style={{ paddingTop: '25px' }}>
                                    <div style={{ marginBottom: '12px' }}>
                                        My {`child's`} executive functions skills are <b>least</b> powered when:
                                    </div>

                                    <div style={{ marginLeft: '15px' }}>{MostPowered}</div>
                                </div>
                            )}
                        </div>
                    )}
                    {strengths && (
                        <div className={style.green_box_section_container}>
                            <div className={style.green_box_section}>Your {`Child's`} Strengths and Abilities</div>
                            <div style={{ paddingTop: '25px' }}>
                                <ul>
                                    {strengths.map(val => (
                                        <li key={val}>{val}</li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    )}
                    <Footer {...props.settings} />
                </div>
            </Page>
        );
    }

    return <></>;
};

ExecutiveFunctionReport.propTypes = {
    settings: PropTypes.object,
    isPreview: PropTypes.bool,
    filters: PropTypes.object,
};

ExecutiveFunctionReport.defaultProps = {};

export default ExecutiveFunctionReport;
