import React from 'react';
import { Report } from 'cccisd-laravel-report';
import MotivateReport from '../Report';

const Component = props => {
    return (
        <div style={{ width: '840px', margin: '0 auto', maxWidth: '100%' }}>
            <Report downloadFilename="MotivateReport" height="600px">
                <MotivateReport {...props} />
            </Report>
        </div>
    );
};

export default Component;
