import React from 'react';
import style from './style.css';

import { Link } from 'cccisd-react-router';
import IconBook from 'cccisd-icons/book3';
import IconBookMark from 'cccisd-icons/bookmarks';

const SecondaryNavBar = () => {
    const currentPageName = window.location.href.split('/').pop();

    return (
        <>
            <div
                className="container"
                style={{
                    height: '50px',
                    backgroundColor: '#21677c',
                    borderTop: '2px solid #31abad',
                    color: 'white',
                    display: 'flex',
                    justifyContent: 'left',
                    alignItems: 'center',
                    paddingLeft: '0',
                }}
            >
                <Link to="/course" className={style.link}>
                    <div
                        className={style.tabButton}
                        style={{ backgroundColor: currentPageName === 'course' ? '#31abad' : '' }}
                    >
                        <IconBook />
                        {` `}Course
                    </div>
                </Link>
                <Link to="/resources" className={style.link}>
                    <div
                        className={style.tabButton}
                        style={{ backgroundColor: currentPageName === 'resources' ? '#31abad' : '' }}
                    >
                        <IconBookMark />
                        {` `}
                        Resources
                    </div>
                </Link>
            </div>
        </>
    );
};

export default SecondaryNavBar;
