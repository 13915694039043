import React from 'react';
import { DeploymentPlayer } from 'cccisd-laravel-assignment';

const CoursesTab = props => {
    return (
        <div style={{ margin: 'auto', maxWidth: '1270px' }}>
            <DeploymentPlayer deploymentHandle="unstuck_course" disableLayout />
        </div>
    );
};

export default CoursesTab;
