import React, { useState, useEffect } from 'react';
import Footer from 'cccisd-footer';
import style from './style.css';
import NavBar from '../../components/NavBar';
import SecondaryNavBar from '../../components/SecondaryNavBar';
import { Redirect } from 'cccisd-react-router';
import licenseQuery from './licenseQuery.graphql';
import _get from 'lodash/get';

import Loader from 'cccisd-loader';

import { client } from 'cccisd-apollo';

const Fortress = window.cccisd && window.cccisd.fortress;
const pawnId = Fortress.user.acting.id;

const replaceNotificationVariables = (content, productLabel, firstName, lastName, expiresDate, daysUntilExpiration) => {
    return content
        .replace('%PRODUCT%', productLabel)
        .replace('%FIRSTNAME%', firstName)
        .replace('%LASTNAME%', lastName)
        .replace('%EXPIRYDATE%', expiresDate)
        .replace('%TIMEFRAME%', daysUntilExpiration);
};

const LearnerLayout = props => {
    const role = Fortress.user.acting.role.handle;

    if (role !== 'learner') {
        return <Redirect to="/" />;
    }

    const firstName = Fortress.user.acting.user.first_name;
    const lastName = Fortress.user.acting.user.last_name;

    const [renewalModalContent, setRenewalModalContent] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [errorMsg, setErrorMsg] = useState(null);

    useEffect(() => {
        async function getLicenseStatus() {
            const resp = await client.query({
                query: licenseQuery,
                variables: { productHandle: ['unstuck'], pawnId },
                fetchPolicy: 'network-only',
            });

            const licenseData = _get(resp, 'data.products.productList[0]', {});
            const licenseStatus = _get(licenseData, 'licenseStatus', 'none');
            const ownedLicenseData = _get(licenseData, 'ownedLicense', {});
            const productLabel = licenseData.label;
            const { expiresDate, expiresDaysAgo } = ownedLicenseData;
            const { expiryNotifications } = licenseData.settings;
            const { voucherStart, voucherRenew, voucherExtend } = licenseData.settings.customNotifications;
            const daysUntilExpiration = Math.abs(expiresDaysAgo);

            if (licenseStatus === 'none') {
                setErrorMsg(
                    replaceNotificationVariables(
                        voucherStart,
                        productLabel,
                        firstName,
                        lastName,
                        expiresDate,
                        daysUntilExpiration
                    )
                );
                setIsLoading(false);
            } else if (licenseStatus === 'expired') {
                setErrorMsg(
                    replaceNotificationVariables(
                        voucherRenew,
                        productLabel,
                        firstName,
                        lastName,
                        expiresDate,
                        daysUntilExpiration
                    )
                );
                setIsLoading(false);
            } else if (licenseStatus === 'active') {
                let expirationNotificationTimeframes = [];
                Object.keys(expiryNotifications).forEach(notificationLevel => {
                    const timeframeNotification = expiryNotifications[notificationLevel];
                    const { timeframe } = timeframeNotification;
                    const { enabled } = timeframeNotification.alert;

                    if (timeframe && daysUntilExpiration <= timeframe && enabled) {
                        expirationNotificationTimeframes.push(timeframe);
                    }
                });

                expirationNotificationTimeframes.sort().reverse();
                let dayKey = _get(expirationNotificationTimeframes, '[0]', false);

                if (dayKey) {
                    Object.keys(expiryNotifications).forEach(notificationLevel => {
                        const timeframeNotification = expiryNotifications[notificationLevel];
                        const { timeframe } = timeframeNotification;
                        if (timeframe === dayKey) {
                            setRenewalModalContent(
                                replaceNotificationVariables(
                                    voucherExtend,
                                    productLabel,
                                    firstName,
                                    lastName,
                                    expiresDate,
                                    daysUntilExpiration
                                )
                            );
                            setErrorMsg(null);
                            setIsLoading(false);
                        }
                    });
                } else {
                    setErrorMsg(null);
                    setIsLoading(false);
                }
            }
        }

        getLicenseStatus();
    }, []);

    return (
        <>
            {isLoading ? (
                <Loader loading />
            ) : (
                <div className={style.wrapper}>
                    <div className={style.header}>
                        <NavBar className="container" />
                        <SecondaryNavBar />
                    </div>
                    <div className={style.body}>
                        {errorMsg ? (
                            <div dangerouslySetInnerHTML={{ __html: errorMsg }} />
                        ) : (
                            <>
                                {renewalModalContent && (
                                    <div
                                        className="alert alert-warning container"
                                        role="alert"
                                        style={{ textAlign: 'center' }}
                                        dangerouslySetInnerHTML={{ __html: renewalModalContent }}
                                    />
                                )}
                                <div className="container">{props.children}</div>
                            </>
                        )}
                    </div>

                    <div className={style.footer}>
                        <Footer className="container" />
                    </div>
                </div>
            )}
        </>
    );
};
export default LearnerLayout;
