import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Page } from 'cccisd-laravel-report';
import style from './style.css';
import { initialValues } from '../Builder';
import devTagsQuery from './devTagsQuery.graphql';
import { client } from 'cccisd-apollo';
import _get from 'lodash/get';

// assets
import logo from './assets/Logos/logo.png';
import footerImage from './assets/Logos/footer_image.png';

const Fortress = window.cccisd.fortress;

const challengesKeyWordDevTags = {
    nag: 'NagChallenge',
    overwhelmed: 'OverwhelmedChallenge',
    start: 'StartingChallenge',
    listen: 'ListenChallenge',
    sensory: 'SensoryChallenge',
    homework: 'HomeworkChallenge',
    bedtime: 'BedtimeChallenge',
};

const AccommodationsReport = props => {
    const { settings } = props;

    const [devTagsData, setDevTagsData] = useState({});

    // inserting initialValues for the case that they don't yet exist through settings (Report GUI builder in app settings)
    for (const key of Object.keys(initialValues)) {
        if (!(key in settings)) {
            settings[key] = initialValues[key];
        } else {
            for (const subkey of Object.keys(initialValues[key])) {
                if (!(subkey in settings[key])) {
                    settings[key][subkey] = initialValues[key][subkey];
                }
            }
        }
    }

    useEffect(() => {
        const pawnId = Fortress.user.acting.id;

        // get user survey answers
        const getMetricsData = async () => {
            const result = await client.query({
                query: devTagsQuery,
                fetchPolicy: 'network-only',
                variables: {
                    pawnId,
                },
            });

            let queryData = {};
            queryData = result.data.flows.assignmentProgress.devTags;

            setDevTagsData(queryData);
        };

        if (!props.isPreview) {
            getMetricsData();
        }
    }, []);

    return <>{settings.page1.show && <Page1 {...settings.page1} devTagsData={devTagsData} settings={settings} />}</>;
};

const Header = props => {
    return (
        <>
            <div className={style.header_container}>
                <img src={logo} alt="" className={style.header_logo} />
            </div>
            <div className={style.header_line} />
            <HeaderTitle />
        </>
    );
};

const Footer = props => {
    const { footerText } = props;
    let value = _get(footerText, 'text', initialValues.footerText.text);
    if (value === '') {
        value = initialValues.footerText.text;
    }

    return (
        <div className={style.footer_container}>
            <span className={style.footer_text}>{value}</span>
            <img src={footerImage} alt="" className={style.footer_img} />
        </div>
    );
};

const HeaderTitle = () => {
    return (
        <div className={style.title}>
            <span className={style.color_green}>Accommodate</span> Accommodations Tracker
        </div>
    );
};

const AccommodationsGrid = props => {
    const { devTagsData } = props;

    const challenge = devTagsData.Challenge ? devTagsData.Challenge : '';
    let accommodationTag = '';
    let accommodation = '';
    for (let i = 0; i < Object.keys(challengesKeyWordDevTags).length; i++) {
        let keyword = Object.keys(challengesKeyWordDevTags)[i];
        if (challenge.toLowerCase().includes(keyword)) {
            let tag = challengesKeyWordDevTags[keyword];
            accommodationTag = tag;
            break;
        }
    }
    accommodation = devTagsData[accommodationTag];

    return (
        <>
            <div className={style.green_box_section}>
                <div className={style.section_title}>Challenge:</div>
                <div
                    style={{
                        fontSize: '15px',
                        width: '580px',
                        alignSelf: 'center',
                        position: 'relative',
                        left: '25px',
                    }}
                >
                    {challenge}
                </div>
            </div>
            <div className={style.green_box_section}>
                <span className={style.section_title}>Accommodation: </span>
                <div
                    style={{
                        fontSize: '15px',
                        width: '505px',
                        alignSelf: 'center',
                        position: 'relative',
                        left: '25px',
                    }}
                >
                    {accommodation}
                </div>
            </div>
            <div className={style.section_grid_container}>
                <div className={style.section_grid_row}>
                    <div
                        className={style.dark_gray}
                        style={{ height: '30px', fontWeight: '500', alignContent: 'center' }}
                    >
                        {' '}
                        <span className={style.category_row_description}>Situation/Date/Time</span>
                    </div>
                    <div
                        className={style.dark_gray}
                        style={{ height: '30px', fontWeight: '500', alignContent: 'center' }}
                    >
                        {' '}
                        <span className={style.category_row_description} style={{ left: '150px' }}>
                            How did it go?
                        </span>
                    </div>
                    <div
                        className={style.dark_gray}
                        style={{ height: '30px', fontWeight: '500', alignContent: 'center' }}
                    >
                        {' '}
                        <span className={style.category_row_description}>(1-5)</span>
                    </div>
                </div>
                <BlankGridRow number={1} />
                <BlankGridRow number={2} />
                <BlankGridRow number={3} />
            </div>
        </>
    );
};

const BlankGridRow = props => {
    const { number } = props;

    return (
        <div className={style.category_data_row}>
            <div style={{ background: 'white', position: 'relative' }}>
                {' '}
                <div style={{ padding: '5px' }} />
                <span style={{ position: 'absolute', left: '4px', top: '0px', fontSize: '20px', fontWeight: '500' }}>
                    {number}.
                </span>
            </div>
            <div style={{ background: 'white', position: 'relative' }}>
                {' '}
                <div style={{ padding: '5px' }} />
            </div>
            <div style={{ background: 'white', position: 'relative' }}>
                {' '}
                <div style={{ padding: '5px' }} />
            </div>
        </div>
    );
};

const Page1 = props => {
    const { devTagsData } = props;

    return (
        <Page>
            <div className={style.page}>
                <Header showGreenBox />
                <div style={{ marginTop: '10px' }}>
                    {`It can take some time to find out if an accommodation is an effective support for your child. Try the accommodation three times, and take notes on how well it works. Each time you use the accommodation, rate it from 1 (Not Successful) to 5 (Successful).`}
                </div>
                <div className={style.numbered_line} />
                <div
                    style={{
                        position: 'relative',
                        width: '100%',
                        display: 'grid',
                        top: '-49px',
                        fontSize: '50px',
                        fontFamily: 'sans-serif',
                        fontWeight: '600',
                        color: 'rgb(50, 172, 174)',
                        gridAutoFlow: 'column',
                        gap: '108px',
                        left: '18px',
                    }}
                >
                    <span>1</span>
                    <span
                        className={style.range_text}
                        style={{
                            width: '75px',
                            textAlign: 'center',
                            left: '-23px',
                            bottom: '-20px',
                        }}
                    >
                        Not Successful
                    </span>
                    <span>2</span>
                    <span>3</span>
                    <span>4</span>
                    <span>5</span>
                    <span className={style.range_text} style={{ right: '18px' }}>
                        Successful
                    </span>
                </div>
                <div style={{ marginBottom: '15px' }}>
                    You can revisit the Accommodate module to select a new challenge or accommodation and print a new
                    tracker.
                </div>
                <AccommodationsGrid devTagsData={devTagsData} />
                <Footer {...props.settings} />
            </div>
        </Page>
    );
};

AccommodationsReport.propTypes = {
    settings: PropTypes.object,
    isPreview: PropTypes.bool,
    filters: PropTypes.object,
};

AccommodationsReport.defaultProps = {};

export default AccommodationsReport;
